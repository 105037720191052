@import url("https://fonts.googleapis.com/css?family=Lato|Poppins|Kaushan+Script");

$background-colour: #ffffff;
$feature-colour: #367588;
$bright-text: white;
$bright-link: #cfd5f0;
$tint-text: whitesmoke;
$purple: #322773;
$pink: #e33cc7;


.has-vertically-aligned-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

* {
  box-sizing: border-box;
}

html {
  font-family: 'Poppins', Lato, Tahoma, sans-serif;
  width: 100%;
  min-height: 100%;
}

.header-wrapper {
  padding-bottom: 30px;
}

.navbar,
.navbar-brand {
  pointer-events: none;

  .navbar-burger {
    pointer-events: auto;
    color: $bright-text;
  }
}

#mobile-nav {
  pointer-events: auto;
}

.hero-foot {
  a.navbar-item {
    padding: 0;
  }
}

.hero {
  background-attachment: fixed;
  background-size: cover;
  color: $bright-text;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  font-family: 'Poppins', sans-serif;
}

#background-video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  // position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
}

.hero-body {
  position: relative;
}

.hero.is-large .hero-body {
  background: linear-gradient(rgba(54, 117, 136, 0.75), rgba(50, 39, 115, 0.75), rgba(50, 39, 115, 0.75)), rgba(0, 0, 0, 0.85) no-repeat;
}


.hero .title {
  font-family: 'Kaushan Script', sans-serif;
  transform: rotate(-5deg);
  font-size: 5rem;
  color: $tint-text;
}

.hero .subtitle {
  padding: 5px;
  color: $tint-text;
}

.hero .profession {
  padding-top: 50px;
  font-size: 1.7rem;
  text-transform: uppercase;
}

.hero .hero-foot {
  height: 50px;
  background: $background-colour;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.082);
}

@media (max-width: 599px) {
  .hero .hero-foot {
    display: none;
  }
}

.hero .hero-foot .hero-foot--wrapper {
  margin: 7px auto;
  height: 50px;
}

.hero .hero-foot .hero-foot--wrapper .hero-menu-desktop {
  line-height: 38px;
  font-weight: 600;
  text-transform: lowercase;
  letter-spacing: 1px;
  font-size: 1rem;
}

.hero .hero-foot .hero-foot--wrapper .hero-menu-desktop ul li {
  display: inline-block;
  padding-right: 15px;
  padding-left: 15px;
}

.hero .hero-foot .hero-foot--wrapper .hero-menu-desktop ul li.is-active a {
  border-bottom: 2px solid #1f2c6c;
}

.hero .hero-foot .hero-foot--wrapper .hero-menu-desktop ul li a {
  color: #1f2c6c;
}

.hero .hero-foot .hero-foot--wrapper .hero-menu-desktop ul li a:hover {
  color: #1f2c6c;
  transition: all .2s ease-in-out;
  border-bottom: 2px solid #1f2c6c;
}

.header-wrapper .hero.standard-page {
  .hero-body {
    padding: 1rem 1rem;
  }

  h1 img {
    max-height: 5rem;
  }
}

.main-content {
  padding-top: 2rem;
  color: #3c4172;
  font-family: 'Poppins', sans-serif;
}

.main-content .section-dark {
  background-color: #f0f3f5;
  padding: 20px 0px;
}

.main-content .section-dark.download {
  height: 400px;
  padding-top: 100px;
}

@media (max-width: 599px) {
  .main-content .section-dark.download {
    padding-top: 75px;
  }
}

.main-content .section-dark.download .title {
  padding: 20px;
}

.main-content .section-dark.download button {
  border: 2px solid $feature-colour;
  background: $background-colour;
  color: $feature-colour;
  height: 50px;
  width: 250px;
  font-size: 1rem;
  text-transform: uppercase;
}

.main-content .section-dark.download .fa-download {
  color: #1f2c6c;
}

.main-content .section-dark.thumbnails .main-content .section-color.thumbnails,
.main-content .section-light.thumbnails {
  padding: 100px;
}

@media (max-width: 599px) {

  .main-content .section-dark.thumbnails,
  .main-content .section-light.thumbnails,
  .main-content .section-color.thumbnails {
    padding: 75px 20px;
  }
}

.main-content .section-dark.thumbnails .work-item,
.main-content .section-light,
.thumbnails .work-item,
.main-content .section-color.thumbnails .work-item {
  margin-bottom: 1rem;
  -webkit-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 5px 20px rgba(14, 25, 80, 0.3);
}

.main-content .section-color.thumbnails {
  background-color: $feature-colour;
  color: $bright-text;

  h1 {
    color: $bright-text;
  }

  a {
    color: $bright-text;
  }

  a:hover {
    color: $bright-link;
  }
}

.main-content .thumbnails .work-item:hover {
  -webkit-transform: scale(1.055) translateY(-2px);
  transform: scale(1.055) translateY(-2px);
  -webkit-box-shadow: 0 2px 25px 0 rgba(30, 30, 30, 0.1);
  box-shadow: 0 2px 25px 0 rgba(30, 30, 30, 0.1);
}

.main-content {
  .columns {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }

  .section-light,
  .section-dark,
  .section-color {
    padding: 30px;
  }
}

.main-content .info-panel p {
  padding-bottom: 1rem;
}

.main-content .section-light.info-panel {
  padding-bottom: 100px;
}

.main-content .section-light.info-panel .is-larger {
  font-size: 1.2rem;
}

@media (max-width: 599px) {
  .main-content .section-light.info-panel .about-links {
    text-align: center;
  }
}

.main-content .info-panel .right-image img {
  border-radius: 11px;

  @media (min-width: 769px) {
    // margin: 20px;
  }

  box-shadow: 0 5px 20px rgba(14, 25, 80, 0.3);
}

@media (max-width: 599px) {
  .main-content .section-light.info-panel .right-image img {
    margin: auto;
  }
}

.main-content .section-light.progress-bars {
  padding: 100px;
}

@media (max-width: 599px) {
  .main-content .section-light.progress-bars {
    padding: 50px 20px 40px 20px;
    padding-bottom: 20px;
  }
}

.main-content .section-light.progress-bars h1 {
  padding: 10px;
}

.main-content .section-light.contact {
  padding: 100px;
}

.main-content .section-light.contact button {
  width: 100%;
  background-color: $feature-colour;
  color: $bright-text;
  height: 50px;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
}

.main-content .section-color.icon-list {
  padding: 100px;
  background-color: $feature-colour;
  color: $bright-text;
}

.main-content .icon-list {
  @media (max-width: 768px) {
    hr {
      display: none;
    }

    h2 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 3rem;
    }
  }
}

@media (max-width: 599px) {
  .main-content .section-light.contact {
    padding: 60px 20px;
  }

  .main-content .section-color.icon-list {
    padding: 30px;
  }
}

.main-content .subtitle .accordion-toggle {
  margin-right: 0;
  margin-left: auto;
}

.accordion-section {
  width: 100%;
}


.accordion {

  .content {
    padding-bottom: 40px;
    padding-top: 20px;
  }

  .accordion-handle {
    display: flex;
    flex-direction: row;
    background-color: $feature-colour;
    color: $bright-text;
    border-color: #dbdbdb;
    border-width: 1px;
    border-radius: 11px;
    cursor: pointer;
    justify-content: space-between;
    padding-bottom: calc(1em - 1px);
    padding-left: 1em;
    padding-right: 1em;
    padding-top: calc(1em - 1px);
    // text-align: center;
    // white-space: nowrap;
  }

  .accordion-handle .subtitle {
    color: $bright-text;
    display: flex;
    width: auto;
    margin-bottom: 0;
  }

  .icon {
    margin-top: 0.6rem;
    width: 1.2rem;
    height: 0.6rem;
    transition: transform 0.3s ease-in-out;
    transform-origin: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 81.876 46.831 28.784" xmlns="http://www.w3.org/2000/svg"><polygon style="fill: currentColor;" points="4.984 81.882 23.487 99.673 42.007 81.876 46.831 86.697 23.487 110.66 0 86.705" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -1.4210854715202004e-14)"/></svg>');
    opacity: 0.6;
  }

  ul {
    list-style-type: disc;
  }

  .intro {
    padding-top: 1.2rem;
  }

  .list-title {
    font-weight: 300;
  }

  .open {
    .icon {
      transform: rotate(180deg);
    }

    .inner {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transition-property: opacity, transform;
    }

    .content {
      max-height: 100rem;
      transition-delay: 0s;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.5s;
      transition-property: max-height;
    }
  }

  .content {
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    //transition-delay: 0.5s;
    z-index: 1;
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
  }

  .inner {
    opacity: 0;
    padding-bottom: 2.4rem;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.5s;
    transition-property: opacity, transform;
    transition-delay: 0.5s;
    // padding: 0 1.2rem 1.2rem;
  }

  .accordion-section {
    padding-bottom: 1.2rem;
  }
}



.main-content .markdown {
  padding: 100px;

  .body {

    p,
    ul,
    ol,
    li,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding-bottom: 0;
      margin-bottom: 1rem;
    }

    ul {
      list-style-type: disc;
    }

    ul,
    ol {
      margin-left: 2rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 300;
      font-size: 1.125em;
    }
  }
}

@media (max-width: 599px) {
  .main-content .markdown {
    padding: 20px 20px;
  }
}

.main-content .section-color.icon-list .title,
.main-content .section-color.icon-list .subtitle {
  color: $bright-text;
}

.main-content .section-color.icon-list i {
  font-size: 3rem;
  padding: 20px;
}

.main-content .section-color.accordion .title,
.main-content .section-color.accordion .subtitle {
  color: $bright-text;
}

.main-content .section-color.accordion i {
  font-size: 3rem;
  padding: 20px;
}

.main-content .section-color.accordion {
  padding: 100px;
  background-color: $feature-colour;
  color: $bright-text;

  .accordion-handle,
  .accordion-handle .subtitle {
    background-color: $background-colour;
    color: $feature-colour;
  }

  .h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $bright-text;
    font-weight: 300;
    font-size: 1.125em;
  }
}

@media (max-width: 599px) {
  .main-content .section-color.accordion {
    padding: 30px;
  }
}

.main-content .section-title {
  color: $feature-colour;
  text-transform: uppercase;
  font-size: 1.8rem;
  letter-spacing: 2px;
  padding-bottom: 30px;
}

#root .footer {
  bottom: 0;
  width: 100vw;
  padding: 0;
  min-height: 80px;
  line-height: 25px;
  text-align: center;
  background: #1f2c6c;
  color: $tint-text;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 5em;
  padding-top: 2em;

  a, span {
    padding: 0rem 1rem 1rem 1rem;
    color: lightgrey;
  }

  a i, span i {
    font-size: 1.5rem;
    padding: 0px 10px;
  }

  a .nav-item {
    color: $tint-text;
  }
}


.fa-download {
  color: $feature-colour;
}

#toTop {
  height: 50px;
  width: 50px;
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: $pink;
  opacity: 0.6;
  color: $bright-text;
  cursor: pointer;
  border-radius: 50%;
  font-size: 2rem;
  //box-shadow: 0 5px 20px rgba(14, 25, 80, 0.3);
}

#toTop a {
  color: $bright-text;
}

#toTop:hover {
  background-color: $pink;
  opacity: 1;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(245, 245, 245, 0);
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $feature-colour;
}

body.preloader-site {
  overflow: hidden;
}

.preloader-wrapper {
  height: 100%;
  width: 100%;
  background: #1f2c6c;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
}

.preloader-wrapper .preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 120px;
}

.white {
  color: ghostwhite
}

.list-title {
  /*font-size: 1.5rem;*/
  font-weight: 600;
  color: #1f2c6c;
  padding-bottom: 10px;
  padding-top: 20px;
}

#toTop {
  svg {
    max-width: 30px;
    padding-bottom: 5px;
  }
}

.skip-to-content-link {
  left: 50%;
  position: absolute;
  transform: translateY(-100%);
  border-radius: 5px;
  border: 5px solid $feature-colour;
  z-index: 9999;
  font-weight: bold;
}

.skip-to-content-link:hover {
  color: #CCC;
}

.skip-to-content-link {
  background: $purple;
  color: $bright-text;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
}

.skip-to-content-link:focus {
  transform: translateY(0%);
}